<template>
    <div class="content">
        <div class="bullet-box">
            <bullet :bulletData="winUserData" :bulletStatus="bulletStatus" v-if="winUserData.length" @updateStatus="updateStatus"></bullet>
        </div>
        <!-- 二维码 -->
        <div class="weChat" v-if="actShowDataEwm ==1">
            <img :src="actShowDataCode" />
            <text>扫码参与活动</text>
        </div>
        <!-- 奖品池 -->
        <div class="box-prize">
            <div class="box-prize-index">
                <div class="prize-index-title">奖品池</div>
                <div class="prize-index-column">
                    <marquee-top :marqueeData="actShowData"></marquee-top>
                </div>
            </div>
        </div>
        <!-- 开始抽奖 -->
        <div class="box-draw">
            <div class="box-draw-index">
                <div class="warp">
                    <div class="draw-index-project">奖品：{{rewardInfo.reward_name}}</div>
                    <div class="draw-index-images">
                        <img :src="rewardInfo.reward_image" alt="img">
                    </div>
                    <div class="draw-index-run">
                        <div :class="['draw-btn', drawBtnStatus ? 'draw-btn-active' : '']">
                            <img src="https://img.chinamallsales.com/lotteryDraw/draw-index-btn.png" alt="img">
                        </div>
                        <div class="draw-switch">
                            <img v-if="!resultStatus" src="https://img.chinamallsales.com/lotteryDraw/up.png" alt="img">
                            <img v-else src="https://img.chinamallsales.com/lotteryDraw/down.png" alt="img">
                        </div>
                    </div>
                    
                    <div class="draw-index-prize">
                        <div class="prize-bg-few">{{rewardInfo.reward_grade_name}}</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 参与活动人 -->
        <div class="box-actor">
            <div class="box-actor-index">
                <div class="actor-index-title">
                    <h3>参与活动人</h3>
                    <text>{{JoinUserData.length||0}}人</text>
                </div>
                <div class="actor-index-column">
                    <ul class="column-ul">
                        <li class="column-ul-lis" v-for="(item, index) in JoinUserData" :key="index">
                             <div class="head">
                                <img :class="['head-border', isShowLottery && 'head-border-animation']" src="https://img.chinamallsales.com/lotteryDraw/head-border.png" alt="img">
                                <img :class="['head-img', isShowLottery && 'head-img-animation']" :src="item.avatar || 'https://img.chinamallsales.com/lotteryDraw/head-default.png'" alt="img">
                             </div>
                             <p>{{item.nickname}}</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <!-- 中奖结果 -->
    <div class="box-result" v-if="resultStatus">
        <div class="box-result-bg"></div>
        <div class="box-result-index">
            <div class="result-index-head">
                <img src="https://img.chinamallsales.com/lotteryDraw/result-index-head.png" alt="img">
            </div>
            <div class="result-index-close" @click="handleResultClose">
                <img src="https://img.chinamallsales.com/lotteryDraw/result-index-close.png" alt="img">
            </div>
            <div class="result-index-column">
                <ul class="column-ul" v-if="rewardInfo.is_draw && rewardInfo.winners.length">
                    <li class="column-ul-lis" v-for="(item, index) in rewardInfo.winners" :key="index">
                        <div class="head">
                            <img class="head-border" src="https://img.chinamallsales.com/lotteryDraw/head-border.png" alt="img">
                            <img class="head-img" :src="item.avatar || 'https://img.chinamallsales.com/lotteryDraw/head-default.png'" alt="img">
                        </div>
                        <p>{{item.nickname}}</p>
                    </li>
                </ul>
                <div class="result-none" v-else-if="rewardInfo.is_draw && !rewardInfo.winners.length">
                    <img src="https://img.chinamallsales.com/lotteryDraw/none.png">
                    <text>哎呀！没中奖，下次继续努力吧～</text>
                </div>
            </div>
        </div>
    </div>

    <!-- 倒计时动画 -->
    <div class="interactive" v-if="isDownNum">
        <div class="box-result-bg"></div>
        <div class="interactive_box">
            <img class="interactive_bg" src="https://img.chinamallsales.com/screenLottery/interactive_bg.png" alt="img">
            <div class="countdown" v-if="isShowImge">
                <img :src="cutImage">
            </div>
            <div class="textBox" v-if="isShowLottery">
                <div class="rect1">正</div>
                <div class="rect2">在</div>
                <div class="rect3">抽</div>
                <div class="rect4">奖</div>
                <div class="rect5 punctuation ">.</div>
                <div class="rect6 punctuation ">.</div>
                <div class="rect7 punctuation ">.</div>
            </div>
        </div>
    </div>
    
</template>

<script>
import bullet from "@/components/Bullet/bullet.vue"; 
import marqueeTop from '@/components/Marquee/marquee.vue'
import {
    getDapingLotteryActShow, 
    getDapingLotteryJoinUser,
    getDapingLotteryRewardInfo,
    getDapingLotteryWinUser,
} from "@/api/remarkMessage";

export default {
    components: {
        bullet,
        marqueeTop
    },
    data(){
        return{
            timer: null,
            userTimer: null,
            actTimer: null,
            waitTimer: null,
            lotteryTimer: null,
            suffleTimer: null,
            bulletStatus: false,
            drawBtnStatus: false, //开奖按钮动画状态
            resultStatus: false,    //中奖结果显示弹窗
            
            isDownNum:false,
            cutImage:'',

            isExpired: false,//是否过期
            isExist: false,//是否参加成功

            isShowImge: false, //显示定时器
            isShowLottery: false,//正在抽奖等待返回
            waitDownNum: 1,//测试接口回调时间
            countDownNum: 6,
            
            actId: null,   //活动ID
            lsatId: 0,       //获取下一条信息ID
            actShowData: [],  //奖品池
            JoinUserData: [],  //参与活动人
            rewardInfo: {},  //抽奖活动奖品
            winUserData: [],  //中奖记录
            idx: 0,
            actShowDataEwm: '',
            actShowDataCode: '',
        }
    },
    mounted() {
      this.actId = this.$route.query.id || 2
      this.getActShow()
      this.getRewardInfo()
      this.getJoinUser()
      this.userTimer = setInterval(this.getJoinUser, 5000)
      this.actTimer = setInterval(this.getActShow, 30000)
      this.lotteryTimer = setInterval(this.getRewardInfo, 10000)
    },
    methods: {
        //抽奖活动信息
        getActShow() {
            console.log("更新了");
            getDapingLotteryActShow({act_id: this.actId}).then(res=>{
                if(res.status == 200){
                    console.log(this.actShowData.length)
                    if(this.actShowData.length ==0) this.actShowData = res.data.reward_info
                    this.actShowDataEwm = res.data.ewm
                    this.actShowDataCode = res.data.app_code
                    if(res.data.status==3){
                        this.clearFunction()
                        return false
                    }
                }
            })
        },
        //参与用户信息
        getJoinUser(){
            getDapingLotteryJoinUser({ act_id: this.actId,last_id: this.lsatId }).then(res=>{
                if(res.status == 200){
                    if(res.data.data.length){
                        this.JoinUserData = [...res.data.data, ...this.JoinUserData]
                        this.lsatId = res.data.data ? res.data.data[0].id : 0
                    }
                }
            })       
        },
        //抽奖活动奖品信息  10秒获取一次
        getRewardInfo(){
            getDapingLotteryRewardInfo({act_id: this.actId}).then(res => {
                if(res.status == 200){
                    console.log('抽奖活动奖品信息:',res.data)
                    let info = res.data
                    this.rewardInfo = info
                    info.is_draw == 1 && this.toDown()
                    if(info.act_status ==2 && info.is_draw == 0 ) this.resultStatus = false
                }
            }) 
        },
        //活动中奖纪录
        getWinUser(){
            getDapingLotteryWinUser({act_id: this.actId,reward_grade: 0}).then(res => {
               if(res.status == 200 && res.data.length > 0){
                    res.data.forEach(item => item.line = 0)
                    this.winUserData = res.data
                }
            })
        },
        handleResultClose(){
            this.resultStatus = false;
        },
        toDown:function(){
            const rewardInfo = this.rewardInfo;
            this.resultStatus = false;
            let newTime =  Math.round(new Date().getTime()/1000).toString();
            let lastTime= (rewardInfo.draw_time+rewardInfo.count_down) - newTime;
            console.log(newTime)
            console.log(lastTime)
            if(lastTime>=0&&rewardInfo.is_win!=2){
                clearInterval(this.lotteryTimer);
                this.countDownNum = lastTime > rewardInfo.count_down ? rewardInfo.count_down : lastTime
                this.countDown()
            }else{
                clearInterval(this.timer); clearInterval(this.waitTimer)
            }
            if(lastTime<0 && rewardInfo.act_status == 3 ) this.getWinUser()
        },
        //定时器
        countDown: function () {
            let countDownNum = this.countDownNum
            clearInterval(this.lotteryTimer)
            this.timer = setInterval(() => {
                countDownNum--
                this.countDownNum = countDownNum
                if(countDownNum == 6) this.drawBtnStatus = true
                if (countDownNum <= 5) {
                    this.isDownNum= true,
                    this.isShowImge= true,
                    this.cutImage= 'https://img.chinamallsales.com/screenLottery/interactive_' + countDownNum + '.png'
                }
                if (countDownNum < 0) {
                    clearInterval(this.timer);
                    this.isShowImge = false
                    this.isShowLottery= true
                    this.waitBack()
                    this.suffleTimer = setInterval(() => this.JoinUserData = this.shuffle(this.JoinUserData), 400)
                }
            }, 1000)
        },
        //等待返回
        waitBack: function () {
            let waitDownNum = this.waitDownNum;
            let rewardInfo = this.rewardInfo
            clearInterval(this.lotteryTimer)
            this.waitTimer = setInterval(() => {
                waitDownNum--
                this.waitDownNum = waitDownNum
                if (waitDownNum < 0) {
                    clearInterval(this.waitTimer)
                    clearInterval(this.suffleTimer)
                    this.bulletStatus = true
                    this.isDownNum = false,
                    this.isShowLottery = false,
                    this.resultStatus = true,
                    this.getWinUser()

                    this.lotteryTimer = setInterval(() =>this.getRewardInfo(), 10000)
                    const status = rewardInfo && rewardInfo.act_status == 3 ? true : false;
                    if(status){
                        this.clearFunction()
                        setTimeout(() => {
                            this.resultStatus = false;
                        } ,10000)
                    }
                }
            }, 1000)
        },
        clearFunction(){
            clearInterval(this.lotteryTimer);
            clearInterval(this.timer);
            clearInterval(this.waitTimer);
            clearInterval(this.userTimer);
            clearInterval(this.actTimer);
            
        },
        shuffle(arr) {
            return arr.sort(() => Math.random() - 0.5);
        },
        updateStatus(res){
            this.bulletStatus = res
        },
    }
}
</script>


<style lang="less" scoped>
*{
    box-sizing: border-box;
}
::-webkit-scrollbar { width: 11px; height: 11px; } 
::-webkit-scrollbar-thumb { border-radius: 10px; background-color: rgba(0, 0, 0, 0.2); border: 2px solid transparent; background-clip: padding-box; }
.over-box,.start-box{
    width: 100%;
    height: 100%;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.content{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0 20px;
    display: flex;
    background-color: #B70004;
    justify-content: space-between;
    .bullet-box{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9;
    }
    .weChat {
        position: fixed;
        bottom: 25px;
        left: 79px;
        width: 160px;
        height: 198px;
        background-color: #fff;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 1;
        > text {
            font-size: 20px;
            margin-top: 10px;
            color: #000;
            font-weight: bold;
        }
        > img {
            width: 140px;
            height: 140px;
        }
    }
    .box-prize{
        width: 278px;
        .box-prize-index{
            width: 100%;
            height: 462px;
            background-color: #B70003;
            border: solid #FF4C00 2px;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
            overflow: hidden;
            .prize-index-title{
                text-align: center;
                color: #fff;
                font-size: 20px;
                width: 100%;
                height: 50px;
                line-height: 50px;
                letter-spacing: 1px;
                background-color: #FF4C00;
            }
            .prize-index-column{
                margin-top:10px;
                width: 100%;
                height: 395px;
                padding: 0 8px 12px 8px;
                overflow: hidden;
            }
        }
    }
    .box-draw{
        width: 100%;
        display: flex;
        justify-content: center;
        overflow: hidden;
        position: fixed;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
        .box-draw-index{
            width: 600px;
            height: 700px;
            background: url(https://img.chinamallsales.com/lotteryDraw/box-draw-index-bg.png?2) no-repeat bottom left;
            background-size: 600px 700px;
            display: flex;
            align-items: center;
            flex-direction: column;
            .warp{
                width: 290px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                margin-top: 194px;
                height: 100%;
                .draw-index-project{
                    width: 286px;
                    height: 30px;
                    background: #EE7100;
                    border-radius: 15px;
                    border: 3px solid #FFE759;
                    text-align: center;
                    color: #fff;
                }
                .draw-index-images{
                    width: 290px;
                    height: 215px;
                    z-index: -1;
                    margin-top: -14px;
                    margin-left: -6px;
                    >img{
                       width: 290px;
                       height: 215px;
                    }
                }
                .draw-index-run{
                    display: flex;
                    justify-content: flex-end;
                    width: 100%;
                    .draw-btn{
                        width: 241px;
                        height: 78px;
                        margin-bottom: 14px;
                        margin-right: 10px;
                        >img{
                            width: 241px;
                            height: 78px;
                        }
                    }
                    .draw-btn-active{
                        animation: draw-btn-animation 1s linear;
                    }
                    .draw-switch{
                        width: 29px;
                        height: 64px;
                        padding-top: 10px;
                        margin-right: -6px;
                        >img{
                            width: 29px;
                            height: 64px;
                        }
                    }
                }
                .draw-index-prize{
                    width: 245px;
                    height: 95px;
                    background: #FF8000;
                    border-radius: 22px 22px 0px 0px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                    .prize-bg-few{
                        width: 215px;
                        height: 40px;
                        line-height: 40px;
                        background: #FF9B37;
                        border-radius: 10px;
                        font-size: 20px;
                        color: #000000;
                        margin-top: 15px;
                    }
                }
                
            }
        }
    }
    .box-actor{
        width: 302px;
        .box-actor-index{
            width: 100%;
            height: 675px;
            background: #A00003;
            border-radius: 0px 0px 20px 20px;
            border: 2px solid #FF4C00;
            overflow: hidden;
            .actor-index-title{
                text-align: center;
                width: 100%;
                height: 50px;
                line-height: 50px;
                letter-spacing: 1px;
                background-color: #FF4C00;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                position: relative;
                >h3{
                    margin: 0;
                    padding: 0;
                    font-size: 20px;
                    font-weight: normal;
                }
                >text{
                    position: absolute;
                    right: 5px;
                    font-size: 14px;
                }
            }
            .actor-index-column{
                width: 100%;
                padding: 10px 8px 0 8px;
                margin-top: 10px;
                .column-ul{
                    width: 100%;
                    margin: 0;
                    padding: 0;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    .column-ul-lis{
                        padding: 7px;
                        list-style-type: none;
                        border-radius: 8px;
                        margin-bottom: 8px;
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        >img{
                            width: 74px;
                            height: 74px;
                            border-radius: 50%;
                        }
                        >p{
                            font-size: 14px;
                            color: #fff;
                            margin: 10px auto;
                            width: 70px;
                            text-align: center;
                            overflow:hidden;
                            text-overflow:ellipsis;
                            white-space:nowrap; 
                        }
                    }
                }
            }
        }
    }
}
.box-result{
    width: 100%;
    height: 100%;
    z-index: 97;
    position: fixed;
    top: 0;
    left: 0;
    .box-result-index{
        width: 74%;
        position: relative;
        top: 50%;
        left: 50%;
        z-index: 99;
        transform: translate(-50%, -50%);
        // animation: animated-box-result 1s linear;
        .result-index-head{
            position: absolute;
            left: 50%;
            transform: translate(-50%, -78%);
            width: 276px;
            height: 161px;
            z-index: 3;
            >img{
                width: 276px;
                height: 161px; 
            }
        }
        .result-index-close{
            position: absolute;
            right: -8px;
            top: -8px;
            width: 36px;
            height: 36px;
            >img{
                width: 36px;
                height: 36px; 
            }
        }
        .result-index-column{
            width: 100%;
            background: #FF5344;
            border-radius: 27px;
            border: 10px solid #FFD962;
            max-height: 50vh;
            overflow-y: scroll;
            z-index: 2;
            .column-ul{
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                margin: 40px 0 0 0;
                padding: 0;
                .column-ul-lis{
                    padding: 5px;
                    list-style-type: none;
                    border-radius: 8px;
                    margin-bottom: 8px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin: 10px 20px;
                    >img{
                        width: 74px;
                        height: 74px;
                        border-radius: 50%;
                    }
                    >p{
                        font-size: 14px;
                        color: #fff;
                        padding: 0;
                        width: 100%;
                        text-align: center;
                    }
                }
                
            }
            .result-none{
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 53px 0;
                >img{
                    width: 142px;
                    height: 120px;
                }
                >text{
                    color: #fff;
                    font-size: 14px;
                }
            }
        }
    }
}
.interactive{
    position:fixed;
    width:100%;
    height:100vh;
    bottom:0rpx;
    left:0rpx;
    z-index: 3;
    .interactive_box{
        width: 375px;
        display: flex;
        justify-content: center;
        overflow: hidden;
        position: fixed;
        bottom: 0;
        left: 50%;
        z-index: 99;
        transform: translateX(-52%);
        .interactive_bg{
            width: 375px;
            height: 422px;
        }
        .countDownNum{
            width: 100%;
            height: 140px;
            color: #333;
            font-size: 50px;
            text-align: center;
        }
        .countdown{
            position: absolute;
            bottom: 180rpx;
            left: 30px;
            width: 311px;
            height: 306px;
            >img{
                width:311px;
                height:306px;
                animation: animated-border 1s linear infinite;
            }
        }
        .textBox{
            position: absolute;
            bottom: 286px;
            left: 96px;
            width: 190px;
            height: 50px;
            text-align: center;
            div {
                width: 40px;
                height: 50px;
                color:#fff;
                font-size: 36px;
                font-weight: bolder;
                display: inline-block;
                animation: sk-stretchdelay 1s infinite ease-in-out;
            }
            .punctuation {
                width: 10px;
            }
            .rect2 {
                animation-delay: -0.9s;
            }
            .rect3 {
                animation-delay: -0.8s;
            }
            .rect4 {
                animation-delay: -0.7s;
            }
            .rect5 {
                animation-delay: -0.6s;
            }
            .rect6 {
                animation-delay: -0.5s;
            }
            .rect7 {
                animation-delay: -0.4s;
            }
        }
    }
}

.head{
    position: relative;
    width: 74px;
    height: 74px;
    .head-border{
        position: absolute;
        top: -8px;
        left: -8px;
        width: 90px;
        height: 90px; 
    }
    .head-border-animation{
         animation: head-animation 2s linear infinite reverse;
    }
    .head-img{
        position: absolute;
        left: 0;
        top: 0;
        width: 74px;
        height: 74px;
        border-radius: 50%;
        z-index: 0;
    }
    .head-img-animation{
         animation: head-animation 2s linear infinite;
    }
}
.box-result-bg{
    z-index: 98;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
}
@keyframes animated-border {
	0%{
		transform: scale(0.2);
        opacity: 0;
    }
	50%{ 
		transform: scale(1);
        opacity: 1;
	}
	80%{ 
		transform: scale(1);
        opacity: 1;
    }
    100%{ 
		transform: scale(0.6);
        opacity: 0;
    }
}
@keyframes sk-stretchdelay {
  0%{
    opacity: 1;
  }
	50%{ 
    opacity: 0.5;
  }
  100%{
    opacity: 0;
  }
}
@keyframes draw-btn-animation {
  0%{
    transform: scale(1);
  }
  40%{ 
    transform: scale(0.8);
  }
  80%{ 
    transform: scale(1.1);
  }
  100%{
    transform: scale(1);
  }
}
@keyframes head-animation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
@keyframes animated-box-result{
    from {
        transform: scale(0.5);
    }
    to {
        transform: scale(1);
    }
}
</style>