<template>
    <div class="index-draw-item">
        <div class="item-head">
            <img :src="draw.avatar || 'https://img.chinamallsales.com/lotteryDraw/head-default.png'" alt="img">
        </div>
        <div class="item-info">
            <div class="txt1">{{draw.nickname}}</div>
            <div class="txt2">{{draw.reward_grade_name}}</div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'drawItemComponent',
    props: ['draw']
}
</script>
<style lang="less" scoped>
   .index-draw-item{
      width: auto;
      height: 76px;
      background-color: rgba(0,0,0,0.36);
      border-radius: 38px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding: 8px;
      .item-head{
         width: 60px;
         height: 60px;
         display: flex;
        justify-content: center;
        align-items: center;
         >img{
            width: 60px;
            height: 60px;
            border: 2px solid #FFFFFF;
            border-radius: 50%;
         }
      }
      .item-info{
          width: auto;
          margin-left: 10px;
          font-size: 18px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .txt1{
              color: #FFFFFF;
          }
          .txt2{
              color: #FFE635;
              margin-left: 8px;
          }

      }
   }
</style>