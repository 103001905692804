

<template>
  <div class="bullet-warp">
    <div
      class="bullet-item"
      v-for="item in showingBullets"
      @animationend="removeBullet"
      :key="item.id"
      :data-line="item.line"
    >
      <draw-item :draw="item"></draw-item>
    </div>
  </div>
</template>
<script>
import drawItem from "@/components/Message/drawItem.vue";
export default {
  components: {
    drawItem,
  },
  props: ["bulletData", "bulletStatus"],
  data() {
    return {
      // 将要显示的弹幕队列
      waitBullets: this.bulletData,
      showingBullets: [],
      lines: 2,
      currentLine: 1,
      newBullet: "",
      isInfinite: true,
      timer: null,
    };
  },
  watch: {
    bulletData(val) {
      console.log("🚀 ~ file: bullet.vue ~ line 37 ~ bulletData ~ val", val);
      // this.waitBullets = val;
      console.log(this.bulletStatus);
      if (this.bulletStatus) {
        clearInterval(this.timer);
        this.showingBullets = [];
        this.waitBullets = [];
        this.waitBullets = this.bulletData;
        this.$emit("updateStatus", false);
        this.showNextBullet();
      }
    },
  },
  mounted() {
    this.showNextBullet();
  },
  methods: {
    showNextBullet() {
      if (!this.waitBullets.length) {
        return;
      }
      this.currentLine = (this.currentLine % this.lines) + 1;
      const currentBullet = this.waitBullets.shift();
      this.isInfinite &&
        this.waitBullets.push({
          id: currentBullet.id,
          avatar: currentBullet.avatar,
          nickname: currentBullet.nickname,
          reward_grade_name: currentBullet.reward_grade_name,
          isWished: false,
          line: 0,
        });
      currentBullet.line = this.waitBullets.length == 1 ? 1 : this.currentLine;
      this.showingBullets.push(currentBullet);
      this.timer = setTimeout(this.showNextBullet, 2000);
    },
    removeBullet() {
      this.showingBullets.shift();
      // console.log(this.showingBullets);
    },
  },
  onBeforeUnmount() {
    clearInterval(this.timer);
  },
};
</script>

<style>
html,
body {
  min-height: 100%;
  overflow: hidden;
}
body {
  margin: 0;
}
.bullet-wrap {
  height: 375px;
  background-color: #eee;
  position: relative;
}
.bullet-item {
  position: absolute;
  animation: rightToleft 10s linear both;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}
.bullet-item[data-line="1"] {
  top: 0;
}
.bullet-item[data-line="2"] {
  top: 75px;
}
.bullet-item[data-line="3"] {
  top: 150px;
}
.bullet-item[data-line="4"] {
  top: 225px;
}
.bullet-item[data-line="5"] {
  top: 300px;
}
@keyframes rightToleft {
  0% {
    transform: translateX(100vw);
  }
  100% {
    transform: translateX(-100%);
  }
}
</style>

