<template>
<div class="index-content">
    <div class="marquee-warp" ref="warpBox">
        <!-- <div class="marquee-item" v-for="(item, index) in marqueeData" :key="index" ref="marqueeItem">
            <img :src="item.reward_image" alt="img">
            <p>{{item.reward_name}}</p>
        </div> -->

         <!-- 第一列 -->
        <div class="marquee-column">
            <div class="marquee-item" v-for="(item, index) in data1" :key="index" ref="marqueeItem">
                <!-- https://img.chinamallsales.com/lotteryDraw/prize-default.png -->
                <img :src="item.reward_image" alt="img">
                <p>{{item.reward_name}}</p>
            </div>
        </div>
        <!-- 第二列 -->
        <div class="marquee-column">
            <div class="marquee-item" v-for="(item, index) in data2" :key="index" ref="marqueeItem">
                <img :src="item.reward_image" alt="img">
                <p>{{item.reward_name}}</p>
            </div>
        </div>
    </div>
</div>
</template>

<script>
    export default {
        name:'my-marquee-left',
        props: ['marqueeData'],
        data() {
            return {
                nowTime:null,//定时器标识
                disArr:[],//每个内容的宽度
                sendVal: this.marqueeData,
                data1: [],//第一列
                data2: [], //第二列
                scroll: { 
                    trans: 'trans',
                    top: null,
                    warpHeight: null,
                    itemHeight: null,
                    duration: 4000
                },
            }
        }, 
        watch: {
            marqueeData() {
                this.$nextTick(() => {
                    console.log(this.sendVal.length)
                    if(this.marqueeData.length){
                        let sendVal = this.marqueeData,
                            i = 0;

                        while (i < sendVal.length) {
                            this.data1.push(sendVal[i++]);
                            if (i < sendVal.length) {
                            this.data2.push(sendVal[i++]);
                            }
                        }
                    }
                    if(this.marqueeData.length > 6) {
                       this.scrollMarquee()
                    }
                })
            }
        },
        mounted(){
            
            
        },
        beforeUnmount:function(){
            clearInterval(this.nowTime);//页面关闭清除定时器
            this.nowTime = null;//清除定时器标识
        },
        methods:{
            scrollMarquee() {
                let warpBoxHeight = this.$refs.warpBox
                let startDis = 0;//初始位置
                let that = this;
                let arr = [];
                for(let i = 0;i < this.marqueeData.length;i++){
                    arr.push(137)
                }
                this.disArr = arr;
                this.nowTime = setInterval(function(){
                    startDis -= 0.5;
                    if(Math.abs(startDis) > Math.abs(that.disArr[0])){
                        that.disArr.push(that.disArr.shift())
                        that.data1.push(that.data1.shift())
                        that.data2.push(that.data2.shift())
                        startDis = -10;
                    }
                    warpBoxHeight.style = 'transform: translateY('+ startDis +'px)'; 
                },1000/60)
            }
        }
    }
</script>

<style lang="less" scoped>
    .index-content{
        width: 100%;
    }
    .marquee-warp{
        width: 100%;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        .marquee-column{
            display: flex;
            flex-direction: column; 
        }
        .marquee-item{
            width: 124px;
            height: auto;
            background-color: #900003;
            list-style-type: none;
            border-radius: 8px;
            margin-bottom: 8px;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 5px 0;
            >img{
                width: 114px;
                height: 84px;
                border-radius: 8px;
            }
            >p{
                font-size: 14px;
                color: #fff;
                padding: 0;
                margin: 0;
                text-align: center;
                margin-top: 8px;
            }
        }
        
    }
</style>